.vyzer-logo-loader {
  color: #0F1D2B;
  &.wbg {
    color: rgba(205,205,205,1);
  }
}

.path1 {
  transform-origin: bottom right 90px;
  animation: path1 1.4s ease 200ms infinite;
}


.path2 {
  transform-origin: bottom right 60px;
  animation: path1 1.4s ease infinite;
}

@keyframe svg {
  from {
    tranform: rotate3d(2,4,0,-120deg);
  }
  to {
    tranform: rotate3d(0)
  }
}

@keyframes path1 {
/*     transform: rotate3d 1s forwards; */
  from {
      transform: rotate3d(1,2,0,60deg);
      opacity: 0;
  }
  to {
    transform: rotate3d(0);    
    opacity: 1;
  }

}

@keyframes path2 {
  from {
      transform: rotate3d(1,2,0,-60deg);
      opacity: 0;
  }
  to {
    transform: rotate3d(0);    
    opacity: 1;
  }

}

.loader-back-drop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  z-index: 9999999;
  background: #F1F3F5;
  left: 260px;
  top: 52px;

}

.loader-back-drop.absolute-pos{
  position: absolute;
  top:0;
  left:0;
  opacity: 0.6;
}

@media (max-width: 1023px) {
  .loader-back-drop{ left: 80px };
}

@media (max-width: 768px) {
  .loader-back-drop{ left: 0px; top: 64px; };
  .loader-back-drop.absolute-pos{
    position: fixed;
  }
  .loader-back-drop.mobileFixed-pos{
    position: fixed;
  }
}