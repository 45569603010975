.asset-page-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow-y: auto;
    background-color: #eef0f2;
}

.center-content {
    width: 100%;
    max-width: 800px;
    padding: 0 16px;
    margin: 0 auto;
}

.asset-header {
    padding-top: 16px;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
}

.asset-header-breadcrumbs {
    display: flex;
    flex-direction: row;
    color: #8D939D;
    align-items: center;
}

.asset-header-breadcrumbs span {
    margin-right: 8px;
}

.header-breadcrumbs-item.selected {
    color: #2D3137;
}

.asset-header-content {
    padding: 16px 0 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.asset-details {
    display: flex;
    flex-direction: column;
}

.asset-details h2 {
    margin: 0px;
}

.asset-details .connected-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.asset-details .connected-status .connected-status-text {
    font-size: 12px;
    color: #2d8a00;
}

.asset-details .connected-status .closed-status-text {
    font-size: 12px;
    color: #ff4d4f;
}

.asset-actions .action-button {
    width: 32px;
    height: 32px;
    border: 1px solid #343739;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.asset-action-menu-wrapper {
    width: 180px;
}

.asset-action-menu-position-fix-wrapper {
    position: relative;
    margin-top: 32px;
}

.asset-action-menu-position-fix-wrapper2 {
    position: relative;
    margin-top: 32px;
    border-radius: 8px;
}

.asset-action-menu-content {
    position: absolute;
    width: 180px;
    border: 1px solid #DDE3EB;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    top: -28px;
}

.itempage-asset-action-menu-content {
    position: absolute;
    border: 1px solid #DDE3EB;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    top: -28px;
    white-space: nowrap;
}
.itempage-asset-action-menu-content .asset-action-menu-item{
    padding:8px 16px 8px 12px;
}

@media (max-height: 860px ){
    .asset-action-menu-position-fix-wrapper2{
        margin-top: -92px;
    }
}

.multi-shadows{
    border:none;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.04) , 0px 0px 2px rgba(0, 0, 0, 0.16) ;
}

.asset-action-menu-item {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 44px;
    padding: 8px;
    padding-left: 12px;
    cursor: pointer;
    background-color: #FFFFFF;
    animation: all 0.2s;
}

.asset-action-menu-item:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.asset-action-menu-item:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.asset-action-menu-item-child {
    display: flex;
    align-items: center;
    gap: 8px;
}

.asset-action-menu-item-child.red {
    color: #FF0000;

}

.asset-action-menu-item:hover {
    background: linear-gradient( 90deg, #000  2px, #F7F8F9 2px);
}

.asset-action-menu-item .anticon {
    margin-right: 8px;
}

.asset-tabs-wrapper {
    width: 100%;
    background-color: #FAFAFA;
}

.asset-tabs-wrapper .ant-tabs-tab-btn {
    color: #A1A8B1;
    font-weight: normal;
}

.asset-tabs-wrapper .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
    font-weight: normal;
}

.asset-tabs-wrapper .ant-tabs-ink-bar {
    background-color: #000;
}

.ant-tabs-nav-list {
    gap: 24px;
}

.asset-tabs-wrapper .ant-tabs-top>.ant-tabs-nav {
    margin-bottom: 0px;
}

.tabs-basge .ant-badge-count {
    background-color: #fff;
    color: #999;
    box-shadow: 0 0 0 1px #d9d9d9 inset;
}

.asset-tabs-content {
    padding-top: 24px
}

.asset-overview-tab-wrapper {
    display: flex;
    flex-direction: column;
}

.asset-overview-tab-wrapper .current-value-box {
    background-color: #343739;
    color: #fff;
    padding: 24px;
    margin-bottom: 24px;
}

.asset-overview-tab-wrapper .current-value-box h2 {
    margin: 0px;
    color: #fff;
}

.asset-overview-tab-wrapper .data-box-row {
    margin: 0 -12px;
    display: flex;
    flex-direction: row;
}

.asset-overview-tab-wrapper .data-box {
    margin: 0 12px;
    flex: 1;
    padding: 24px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.asset-overview-tab-wrapper .data-box .current-expected-view {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.asset-overview-tab-wrapper .data-box .current-expected-view>div {
    padding: 2px 12px;
    margin: 0 0px 8px;
    color: #8D939D;
}

.asset-overview-tab-wrapper .data-box .current-expected-view>div.selected {
    background-color: #EEF0F2;
    color: #000;
}

.asset-overview-tab-wrapper .data-box .data-title {
    color: #8D939D
}

.asset-overview-tab-wrapper .data-box h3 {
    margin: 0px;
}

.edit-form-wrapper {
    text-align: left;
}


.asset-transactions-tab-wrapper {
    text-align: left;
}

.asset-transactions-tab-wrapper .loading-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 18px;
}

.asset-transactions-tab-wrapper .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding-left: 24px;
    position: sticky;
    top: 0px;
    background-color: #fbfbfb;
    z-index: 3;

}

.asset-transactions-tab-wrapper .account-transactions-list-wrapper .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    z-index: 2;
    top: 40px;
    border-bottom: 1px solid #bdbdbd;
    padding-right: 0px;
}

.asset-transactions-tab-wrapper .account-transactions-list-wrapper {
    padding: 0px 0px 16px;
}

.asset-transactions-tab-wrapper .account-transactions-list-wrapper .ant-collapse-content>.ant-collapse-content-box {
    padding: 6px;
}

.asset-transactions-tab-wrapper .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    left: 4px;
}

.asset-transactions-tab-wrapper .transaction-group-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.asset-transactions-tab-wrapper .account-transition-row {
    display: grid;
    animation: all 0.15s;
    grid-template-columns: 120px calc(40% - 120px) calc(60% - 100px) 100px;
    margin-bottom: 0px;
    padding-bottom: 8px;
    padding-top: 8px;
    background-color: #fBfBfB;
}

.asset-transactions-tab-wrapper .account-transition-row:hover {
    background-color: #e6e6e6;
}

.asset-transactions-tab-wrapper .account-transition-row>div {
    padding-right: 16px
}

.asset-transactions-tab-wrapper .account-transition-row>div:first-child {
    padding-left: 8px;
    padding-right: 16px
}

.asset-transactions-tab-wrapper .account-transition-row>div:last-child {
    text-align: right;
    padding-right: 8px;
}

.asset-transactions-tab-wrapper .account-transition-row:not(:last-child) {
    border-bottom: 1px solid #dadada;
}


.asset-transactions-tab-wrapper .associate-transition-row {
    display: grid;
    grid-template-columns: 40px 120px calc(40% - 140px) calc(60% - 120px) 100px;
    animation: all 0.15s;
    margin-bottom: 0px;
    padding-bottom: 8px;
    padding-top: 8px;
    background-color: #fbfbfb;
}

.asset-transactions-tab-wrapper .associate-transition-row:hover {
    background-color: #e6e6e6;
}

.asset-transactions-tab-wrapper .associate-transition-row>div {
    padding-right: 16px
}

.asset-transactions-tab-wrapper .associate-transition-row>div:first-child {
    padding-left: 8px;
    padding-right: 16px
}

.asset-transactions-tab-wrapper .associate-transition-row>div:last-child {
    text-align: right;
    padding-right: 8px;
}

.asset-transactions-tab-wrapper .associate-transition-row:not(:last-child) {
    border-bottom: 1px solid #dadada;
}

.asset-transactions-tab-wrapper .associate-transactions-actions-wrapper {
    border-top: 1px solid #bdbdbd;
    /* margin-top: 16px; */
    padding: 16px 0px;
    position: sticky;
    bottom: -16px;
    background-color: #fbfbfb;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.asset-transactions-tab-wrapper .connected-status-text {
    font-size: 12px;
    color: #2d8a00;
}

.asset-transactions-tab-wrapper .assetTransactionsTableWrapper .tableRow {
    display: grid;
    grid-template-columns: 150px 20% auto 20% 80px;
}

.asset-transactions-tab-wrapper .transactions-bucket-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.asset-transactions-tab-wrapper .transactions-bucket-header .ant-badge-status-text {
    margin-left: 0px;
}

.asset-transactions-tab-wrapper .transactions-bucket-header .show-connecteds {
    display: none;
}

.asset-transactions-tab-wrapper .ant-collapse-item-active>.ant-collapse-header .transactions-bucket-header .show-connecteds {
    display: block;
}

.asset-transactions-tab-wrapper .unconnected-asset-transactions-wrapper .transaction-actions {
    border-top: 1px solid #bdbdbd;
    margin-top: 16px;
    padding: 16px 0px;
    position: sticky;
    bottom: -16px;
    background-color: #fbfbfb;
    display: flex;
    justify-content: space-between;
    align-items: center;
}