.community-tooltip-wrapper{ z-index: 6}
.community-chart-tooltip-wrapper{
    border-radius: 8px;
    width:148px;
    color:#272D33;
    padding:0px;
    z-index: 3;
    background-color: #1A1B1D;
    border: 1px solid #494E55;
    display: inline-block;
}

.community-chart-tooltip-wrapper:before{
    content: '';
    display: block;  
    position: absolute;
    left: 66px;
    top: 100%;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top-color: #494E55;
}

.community-chart-tooltip-wrapper:after{
    content: '';
    display: block;  
    position: absolute;
    left: 67px;
    top: 100%;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: #1A1B1D;
}

.community-tooltip-content-wrapper{
    border-radius: 8px;
    display: inline-block;
    position: relative;
}

.community-tooltip-top{
    display: flex;
    flex-direction: column;
    background-color: #1A1B1D;
    padding:16px;
    font-size: 24px;
    border-radius: 8px;
    color:#fff;
}

.community-tooltip-top .community-class-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 4px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    padding: 2px 4px;
}

.community-tooltip-top .community-class-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.community-tooltip-top .community-class-icon > img {
    width: 18px;
    height: 18px;
}

.community-tooltip-top .community-class-title {}

.community-tooltip-value{
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
}

.community-tooltip-value-subtitle {
    font-size: 12px;
    line-height: 14px;
}

.tooltip-bottom{
    display: flex;
    flex-direction: column;
}

.tooltipItemRow{
    display: flex;
    justify-content: space-between;
    font-size:14px;
    padding:4px 16px
}

.tooltipItemRow .item-label{ 
    width: 140px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.tooltipItemRow:first-child{
    padding-top: 16px;
}

.tooltipItemRow:last-child{
    padding-bottom: 16px;
}

