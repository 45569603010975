@font-feature-settings-base : normal;
@font-variant-base : none;
@import '~antd/dist/antd.less';
@font-face {
  font-family: 'Circular';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('../public/fonts/CircularXXWeb-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-style: normal;
  font-weight: 450;
  font-display: swap;
  src: url('../public/fonts/CircularXXWeb-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../public/fonts/CircularXXWeb-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../public/fonts/CircularXXWeb-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../public/fonts/CircularXXWeb-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Domine';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('../public/fonts/Domine-Regular.ttf') format('truetype');
}

// @font-face {
//   font-family: 'Domine';
//   font-style: normal;
//   font-weight: 450;
//   font-display: swap;
//   src: url('../public/fonts/CircularXXWeb-Book.woff2') format('truetype');
// }

@font-face {
  font-family: 'Domine';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../public/fonts/Domine-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Domine';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../public/fonts/Domine-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Domine';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../public/fonts/Domine-Bold.ttf') format('truetype');
}


html{
  @media (min-width: 769px) {
    overflow-y: hidden;
  }
  // overflow-y: hidden;
}


body {
  margin: 0;
  font-family: 'Circular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (max-width: 768px) {
    background-color: #f2f3f5;
  }
  @media (min-width: 769px){
    overflow-y: hidden;
  }
  overflow-x: hidden;
}
.mobile_prevent_scroll{
    overflow-y:hidden;
    // position:fixed;
}


body > #root {
  // height:100vh;
  @media (min-width: 769px) {
    height:100%;
  }
  // height:100%;

  --Main-Colors-White: #fff;
}



@import 'react-toastify/dist/ReactToastify.css';

.appMenuDrawerContainer_mobile{
  z-index: 2;
  .ant-drawer-body{
    // padding: 64px 0 0;
    padding:0 0;
    background-color: #111213;
  }
}

.appUserNotificationsDrawerContainer_mobile{
  z-index: 3;
  .ant-drawer-body{
    // padding: 64px 0 0;
    padding:64px 0 0;
    background-color: #111213;
  }
}

.simulationDrawerContainer .ant-drawer-body{padding:0px;background-color:#FDFDFD}
.transactionsDrawerContainer .ant-drawer-body{padding:0px;background-color:#FDFDFD}
.addSimulationDrawerContainer {
  .ant-drawer-body{background-color:#FDFDFD}
  .ant-drawer-content-wrapper{max-width: 100%;}
}

.filtersDrawerContainer .ant-drawer-body{padding:0;background-color:#fff}
.allocationDrawerContainer .ant-drawer-body{padding:0;background-color:#fff}
.overviewInflowOutflowDrawer .ant-drawer-body{padding:0;background-color:#fff}
.addItemDrawerContainer_mobile .ant-drawer-body{padding:0;background-color:#fff}


.editEventDrawerContainer_mobile .ant-drawer-content{border-radius: 20px 20px 0 0;}
.editEventDrawerContainer_mobile .ant-drawer-body{padding:0;background-color:#fff;display: flex;flex-direction: column;}
// .editRoleDrawerContainer_mobile {max-height: calc(100% - 32px);}
.editRoleDrawerContainer_mobile .ant-drawer-content-wrapper{max-height:100%;}
.editRoleDrawerContainer_mobile .ant-drawer-content{border-radius: 20px 20px 0 0; max-height:100%;}
.editRoleDrawerContainer_mobile .ant-drawer-body{padding:0;background-color:#fff;display: flex;flex-direction: column; max-height:100%;}
.phoneInputDrawerContainer_mobile .ant-drawer-content-wrapper{max-height:100%;}
.phoneInputDrawerContainer_mobile .ant-drawer-content{border-radius: 20px 20px 0 0;max-height:100%;}
.phoneInputDrawerContainer_mobile .ant-drawer-body{padding:0;background-color:#fff;display: flex;flex-direction: column; max-height:100%;}
.editCustomClassDrawerContainer_mobile .ant-drawer-content-wrapper{max-height:100%;}
.editCustomClassDrawerContainer_mobile .ant-drawer-content{max-height:100%;}
.editCustomClassDrawerContainer_mobile .ant-drawer-body{padding:0;background-color:#fff;display: flex;flex-direction: column; max-height:100%;}
.editHoldingEntityDrawerContainer_mobile .ant-drawer-content-wrapper{max-height:100%;}
.editHoldingEntityDrawerContainer_mobile .ant-drawer-content{max-height:100%;}
.editHoldingEntityDrawerContainer_mobile .ant-drawer-body{padding:0;background-color:#fff;display: flex;flex-direction: column; max-height:100%;}
.editCustomSubClassDrawerContainer_mobile .ant-drawer-content-wrapper{max-height:100%;}
.editCustomSubClassDrawerContainer_mobile .ant-drawer-content{border-radius: 20px 20px 0 0; max-height:100%;}
.editCustomSubClassDrawerContainer_mobile .ant-drawer-body{padding:0;background-color:#fff;display: flex;flex-direction: column; max-height:100%;}
.filtersDrawerContainer_mobile .ant-drawer-content{border-radius: 20px 20px 0 0;}
.allocationDrawerContainer_mobile .ant-drawer-content{border-radius: 20px 20px 0 0;}
.overviewInflowOutflowDrawer_mobile .ant-drawer-content{border-radius: 20px 20px 0 0;}
.asset_actions_DrawerContainer_mobile .ant-drawer-body{padding:0;background-color:#FDFDFD}
.limitationWarningDrawerContainer_mobile .ant-drawer-body{padding:0;background-color:#FDFDFD}
.asset_actions_DrawerContainer_mobile .ant-drawer-content{border-radius: 20px 20px 0 0;}
.limitedAccessDrawerContainer_mobile {z-index: 1001;}
.limitedAccessDrawerContainer_mobile .ant-drawer-content{border-radius: 20px 20px 0 0; }
.limitedAccessDrawerContainer_mobile .ant-drawer-body{padding: 0; }
.more-on-insights-drawer-mobile .ant-drawer-body{padding:0;background-color:#fff}
.more-on-insights-drawer-mobile .ant-drawer-content{border-radius: 20px 20px 0 0; }
.cashAccountDatePicker .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {background-color:#000; }
.documentRowDatePicker .ant-picker-cell, .ant-picker-cell-start, .ant-picker-cell-end, .ant-picker-cell-in-view {color: #000; }
.cashflowDatePicker .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {background-color:#000; }
.ant-select-dropdown.headerSearchBoxDropdown{
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding:8px 0;
  /* margin-top: 24px; */
}
.editEventDatePicker .ant-picker-cell-in-view.ant-picker-cell-disabled .ant-picker-cell-inner { text-decoration: line-through; color: #DDE3EB; background-color: white; }
.editEventDatePicker .ant-picker-cell-disabled::before {
  background-color: white;
}
.createEventDatePicker .ant-picker-cell-in-view.ant-picker-cell-disabled .ant-picker-cell-inner { text-decoration: line-through; color: #DDE3EB; background-color: white; }
.createEventDatePicker .ant-picker-cell-disabled::before {
  background-color: white;
}

.tickersSearchDrawerWrapper_mobile .ant-drawer-body{padding:0px;background-color:#FDFDFD}

.createItemDrawerContainer_mobile .ant-drawer-body{padding:0;background-color:#fff; display: flex;flex-direction: column; }
// .createItemDrawerContainer_mobile .ant-drawer-content-wrapper{max-height: 100%; }

.ant-select-dropdown.headerSearchBoxDropdown_mobile{
  width:calc(100% - 16px);
  transform: translateX(-8px);
  background-color: #111213;
  border: 1px solid #3B3F46;
}

.ant-select-dropdown.connectLiabilitySearchBoxDropdown_mobile{
  width:calc(100% - 16px) !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
/* .ant-select-dropdown.headerSearchBoxDropdown > div{
  margin-top: 8px;
} */
.ant-select-dropdown.headerSearchBoxDropdown .ant-select-item{
  padding:8px 16px;
  border-left: 2px solid #fff;
}

.ant-select-dropdown.headerSearchBoxDropdown_mobile .ant-select-item{
  border-left: 2px solid #111213;
}

.ant-select-dropdown.headerSearchBoxDropdown .ant-select-item:first-of-type::before{
 
}
.ant-select-dropdown.headerSearchBoxDropdown .ant-select-item:last-of-type{
 
}
.headerSearchBoxDropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) { border-left: 2px solid #1A1B1D; background-color: #F7F8F9; }
.headerSearchBoxDropdown_mobile .ant-select-item-option-active:not(.ant-select-item-option-disabled) { border-left: 2px solid #1A1B1D; background-color: #111213; }

.ant-select-dropdown.InstitutionAutoCompleteDropdown{
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding:8px 0;
  z-index: 4;
  /* margin-top: 24px; */
}
/* .ant-select-dropdown.InstitutionAutoCompleteDropdown > div{
  margin-top: 8px;
} */
.ant-select-dropdown.InstitutionAutoCompleteDropdown .ant-select-item{
  padding:8px 16px;
  border-left: 2px solid #fff;
}
.ant-select-dropdown.InstitutionAutoCompleteDropdown .ant-select-item:first-of-type::before{
 
}
.ant-select-dropdown.InstitutionAutoCompleteDropdown .ant-select-item:last-of-type{
 
}
.InstitutionAutoCompleteDropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) { border-left: 2px solid #1A1B1D; background-color: #F7F8F9; }

.input_switcher_default_select .select_arrow{
  transition: 0.2s all;
}

.input_switcher_default_select.ant-select-open .select_arrow{
  transform: rotate(180deg);
}

.formDisplayerDefaultSelect .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  /* background-color: black; */
}
.formDisplayerDefaultSelect .ant-select-item-option{
  padding:8px 16px;
  position:relative;
  color:#1A1B1D;
}
.formDisplayerDefaultSelect .ant-select-item-option::before{
  content:' ';
  position:absolute;
  left:0;
  top:0%;
  height:100%;
  width:0px;
  background-color:#1A1B1D;
}
.formDisplayerDefaultSelect .ant-select-item-option:hover{
  background-color: #F7F8F9;
}
.formDisplayerDefaultSelect .ant-select-item-option:hover::before {
  width:2px;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #F7F8F9;
}

.formDisplayerSelectWithDescriptionOption {
  display: flex;
  min-height: 52px;
  align-items: center;
}

/* .ant-switch.ant-switch-small.ant-switch-checked { */
.ant-switch-checked {
  background: #1A1B1D;
}

/* .formDisplayerDefaultSelect {
  background-color: red;
} */

.ant-popover.darkThemePopover {
  max-width: 80%;
  border-radius: 8px;
}

.ant-popover.darkThemePopover.mobile .ant-popover-inner {
  margin-left: 10px;
}

.ant-popover.darkThemePopover .ant-popover-inner{
  background: #1A1B1D;
  border-radius: 8px;
  max-width: 240px;
}

.ant-popover.darkThemePopover .ant-popover-arrow .ant-popover-arrow-content{
  background: #1A1B1D;
}

.ant-popover.darkThemePopover .ant-popover-arrow {
  bottom: 3px;
  margin-bottom: -20px;
}

.ant-popover.darkThemePopover .ant-popover-inner .ant-popover-title{
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  white-space: pre-line;
  border-bottom: none;
  margin-bottom: -20px;

}

.ant-popover.darkThemePopover .ant-popover-inner .ant-popover-inner-content{
  display: none;
}

.ant-popover.userProfilePopover {
  width: 258px;
}

.ant-popover.userProfilePopover.demo {
  width: 246px;
}

.ant-popover.userProfilePopover .ant-popover-content {
  padding-left: 16px;
  // width: 226px;
}

.ant-popover.userProfilePopover .ant-popover-content .ant-popover-arrow {
  padding-left: 40px;
}

.ant-popover.userProfilePopover .ant-popover-content .ant-popover-arrow .ant-popover-arrow-content {
  background: #111213;
}

.ant-popover.userProfilePopover .ant-popover-content .ant-popover-inner{
  background: none;  
}

.ant-popover.userProfilePopover .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  border: 1px solid #25272C;
  border-radius: 8px;
  background: #111213;
  padding: 8px 16px;
}

.ant-popover.userProfileQuestionnaireMobilePopover{
  width: 258px;
}
.ant-popover.userProfileQuestionnaireMobilePopover .ant-popover-arrow{
  display: none;
}

.ant-popover.userProfileQuestionnaireMobilePopover .ant-popover-content .ant-popover-inner{
  background: none;  
}

.ant-popover.userProfileQuestionnaireMobilePopover .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  border: 1px solid #25272C;
  border-radius: 8px;
  background: #111213;
  padding: 8px 16px;
}

.userProfileIncognitoSwitch.ant-switch {
  background: rgba(255, 255, 255, 0.25 )
}
.userProfileIncognitoSwitch.ant-switch.ant-switch-checked {
  background: #65A87F;
}

.holdingEntityDefaultSwitch.ant-switch.ant-switch-checked {
  background: #65A87F;
}



.dark-tooltip.ant-tooltip{
  .ant-tooltip-inner{
    background: #1A1B1D;
    border-radius: 8px;
    max-width: 240px;
  }
  .ant-tooltip-arrow .ant-tooltip-arrow-content{
    background: #1A1B1D;
  }
}

.ant-popover.checklist-popover{
  .ant-popover-inner{
    border-radius: 4px;
  }
  .ant-popover-content{
    margin-bottom: -12px;
    margin-right: -9px; 
  }
}

.ant-popover.checklist-popover .ant-popover-content .ant-popover-arrow{
  display: none;
}

.ant-popover.checklist-popover .ant-popover-content .ant-popover-inner-content{
  padding: 8px;
}

.ant-popover.shared_portfolios_menu_wrapper .ant-popover-content .ant-popover-arrow{
  display: none;
}
.ant-popover.shared_portfolios_menu_wrapper{
  .ant-popover-inner{
      border-radius: 8px;
      box-shadow: none;
      margin-left: 16px; 
      .ant-popover-inner-content{
        
        padding: 0;
      }
  }
}

.grecaptcha-badge { opacity:0;}

.calendly-overlay{
  z-index:99999999!important;
}

.statInfoBoxDropDownWrapper{
  z-index: 4;
}

.big-width-notification{
  @media (min-width: 481px) {
    width: 400px;
    margin-left: -40px;
  }
}
@font-feature-settings-base: normal;@font-variant-base: none;