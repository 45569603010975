.itempage-chart-tooltip-wrapper {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border: 1px solid #DDE3EB;
    border-radius: 8px;
    padding: 8px 20px 12px 16px;
    z-index: 6;
    pointer-events: none;
}

.itempage-tooltip-top {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 4px;
    color: #1A1B1D;
}

.icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: #EB7E7E;
    margin-right: 8px;
}

.icon-wrapper-with-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 8px;
}

.icon-image-default{
    width: 16px;
    height: 16px;
}

.icon-image{
    width: 24px;
    height: 24px;
}

.itempage-tooltip-bottom {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-direction: row;
}

.bottom-text-wrapper{
    font-weight: 450;
    font-size: 13px;
    line-height: 16px; 
    color: #9097A3;
}

.cashFlow-chart-tooltip-wrapper {
    background: #FFFFFF;
    border: 1px solid #DDE3EB;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.05);
    width: 313px;
    color: #272D33;
    padding: 12px;
    z-index: 6;
}

.cashFlow-chart-tooltip-wrapper:before {
    content: '';
    display: block;
    position: absolute;
    left: calc(50% - 10px);
    top: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #DDE3EB;
    transform: rotate(180deg);
}

.cashFlow-chart-tooltip-wrapper:after {
    content: '';
    display: block;
    position: absolute;
    left: calc(50% - 9px);
    top: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;
    transform: rotate(180deg);
}

.tooltip-top {
    background: #F7F8FA;
    box-shadow: inset 0px -1px 0px #DFE8EE;
    /* padding: 16px; */
    font-size: 24px;
    text-align: center;
}

/* .tooltip-top .tooltip-date {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #585D66;
} */

.cashFlow-chart-tooltip-wrapper .tooltip-top {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #FFFFFF;
    color: #1A1B1D;
    margin-bottom: 8px;
}

.cashFlow-chart-tooltip-wrapper .tooltip-top .tooltip-date {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #585D66;
    background-color: #DDE3EB;
    padding: 3px 6px;
    border-radius: 4px;
    margin-bottom: 4px;
}


.cashFlow-chart-tooltip-wrapper .tooltip-top .tooltip-value {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    color: #1A1B1D;
    margin-bottom: 2px;
}

.cashFlow-chart-tooltip-wrapper .tooltip-top .tooltip-title {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #585D66;
    margin-bottom: 12px;
}

.tooltip-bottom {
    display: flex;
    flex-direction: column;
}

.cashFlow-chart-tooltip-wrapper .cashFlow-tooltipItemRow {
    display: flex;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
}

.cashFlow-chart-tooltip-wrapper .cashFlow-tooltipItemRow:not(:last-of-type) {
    margin-bottom: 8px;
}

.cashFlow-tooltipItemRow .item-label {
    flex: 1;
    padding-right: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.cashFlow-tooltipItemRow .item-value.green {
    color: #6EBA7F;
}


.tooltip-logo {
    height: 24px;
    width: 24px;
    margin-bottom: 16px;
}

.cashFlow-tooltipEmptyState {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #9097A3;
}