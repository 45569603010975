.header-wrapper{
    display: flex;
    width: 100%;
    height: 64px;
    background-color:#FAFAFA;
    justify-content: space-between;
    align-items: center;
}

.logo-wrapper{
    width:160px;
    padding-left: 16px;
}

.status-wrapper{
    display: flex;
    flex-direction: row;
}
.status-wrapper .header-status-link{
    padding:0px 16px;
    font-weight: 500;
    font-size: 14px;
    color:#848C9A;
    cursor: pointer;
    transition: all 0.2s;
    margin-top: 0px;
    display: flex;
}

.status-wrapper .header-status-link:hover{
    color:#2B3549;
}

.status-wrapper .header-status-link.selected{
    color:#2B3549;
    transform: scale(1.15) translateY(-0.5px);
    /* padding-bottom: 1px; */
    /* margin-top: -1px; */
    /* font-weight: 600; */
}

.actions-wrapper{
    width:160px;
    display: flex;
    padding-right:12px;
    justify-content: flex-end;
}

.actions-wrapper .square-button{
    height: 32px;
    width: 32px;
    background-color: #4e5156;
    color: #fff;
    margin:0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer
}
.square-button path{
    stroke: #fff;
}


.user-letter{
    font-size: 16px;
    text-transform: uppercase;
}

.user-action-menu-content{
    width: 200px;
    /* background-color: #b5b5b5; */
    background-color: #f1f1f1;
    /* border: 1px solid #808080; */
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    margin-top:20px;
}
.user-action-menu-item{
    padding: 8px;
    cursor: pointer;
    background-color: #f1f1f1;
    width: 100%;
    animation: all 0.2s;
    display: block;
    color: #000000d9;
}
.user-action-menu-item:hover{
    background-color: #d2cfcf;
    color: #000000d9;
}
.user-action-menu-item .anticon{
    margin-right:8px;
}


.lds-dual-ring {
    display: inline-block;
    width: 22px;
    height: 22px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 16px;
    height: 16px;
    margin: 3px 0 0 6px;
    border-radius: 50%;
    border: 1px solid #848C9A;
    border-color: #848C9A transparent #848C9A transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }